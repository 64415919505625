import React, { useContext, useEffect, useState } from "react";
import { Calendar } from "antd";

import { Row, Col, Card, Table, Typography } from "antd";
import apiUrl from "./config";
import { AuthContext } from "../authService";
import axios from "axios";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";

const columns = [
  {
    title: "Nom",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Téléphone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Adresse",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Date de création",
    dataIndex: "create_at",
    key: "create_at",
    render: (date) => new Date(date).toLocaleDateString(),
  },
  {
    title: "Quantité Commande",
    dataIndex: "order_quantity",
    key: "order_quantity",
  },
  {
    title: "Temps de Récupération (en mois)",
    dataIndex: "recovery_time",
    key: "recovery_time",
  },
  {
    title: "Status de la Commande",
    dataIndex: "order_status",
    key: "order_status",
    render: (status) => (status ? "Actif" : "Inactif"),
  },
  {
    title: "Confirmation de Commande",
    dataIndex: "order_confirmation",
    key: "order_confirmation",
    render: (confirmation) => {
      switch (confirmation) {
        case 1:
          return "Effectué";
        case 0:
          return "Échoué";
        case 2:
          return "En cours";
        default:
          return "Inconnu";
      }
    },
  },
];
const onPanelChange = (value, mode) => {
  console.log(value.format('YYYY-MM-DD'), mode);
};
function Tables() {
  const { user } = useContext(AuthContext);
  const [salesData, setSalesData] = useState([]);
  
  useEffect(() => {
    if (user) {
      axios.get(`${apiUrl}/api/subscriber/`, {
        headers: {
          Authorization: `Token ${user.token}`,
        },
      })
      .then(response => {
        setSalesData(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des données:", error);
      });
    }
  }, [user]);
  const wrapperStyle = {
    width: 350,
    
  };
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={16} lg={14} xl={14}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Abonnés"
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={salesData}
                pagination={false}
                className="ant-border-space"
                rowKey="id"
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={16} lg={6} xl={4}>  
          <Card className="criclebox tablespace mb-24" style={wrapperStyle}>
              <Calendar fullscreen={false}  onPanelChange={onPanelChange} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Tables;
