import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import './lineChartStyles.css';

function LineChart() {
  const { Title, Paragraph } = Typography;

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Historique des ventes</Title>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={300}
        width={"100%"}
      />
      <div className="product-names">
        <div className="product-container gaz">Gaz</div>
        <div className="product-container essence">Essence</div>
        <div className="product-container diesel">Petrol</div>
        <div className="product-container kerosene">Mazout</div>
      </div>
    </>
  );
}

export default LineChart;