import React from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo_2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump } from '@fortawesome/free-solid-svg-icons';

import {
  UserOutlined,
  SolutionOutlined,
  LogoutOutlined,
  TagOutlined,
  DashboardOutlined,
} from '@ant-design/icons';

// Define the blue color
const colorHex = '#1FB7BE'; // This is the color equivalent to 0XFF1FB7BE

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace('/', '');

  // Update the icons to use the new color
  const fournisseurs = [
    <SolutionOutlined key={0} style={{ fontSize: '20px', color: colorHex }} />,
  ];
  const logout = [
    <LogoutOutlined key={0} style={{ fontSize: '20px', color: colorHex }} />,
  ];
  const produit = [
    <TagOutlined key={0} style={{ fontSize: '20px', color: colorHex }} />,
  ];
  const dashboard = [
    <DashboardOutlined key={0} style={{ fontSize: '20px', color: colorHex }} />,
  ];
  const abonnee = [
    <UserOutlined key={0} style={{ fontSize: '20px', color: colorHex }} />,
  ];

  const ventes = [
    <FontAwesomeIcon key={0} icon={faGasPump} style={{ fontSize: '20px', color: colorHex }} />,
  ];

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>IN STATION</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === 'dashboard' ? color : '',
              }}
            >
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/pompiste">
            <span
              className="icon"
              style={{
                background: page === 'pompiste' ? color : '',
              }}
            >
              {ventes}
            </span>
            <span className="label">Pompiste</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/produits">
            <span
              className="icon"
              style={{
                background: page === 'produits' ? color : '',
              }}
            >
              {produit}
            </span>
            <span className="label">Produits</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/abonnee">
            <span
              className="icon"
              style={{
                background: page === 'abonnee' ? color : '',
              }}
            >
              {abonnee}
            </span>
            <span className="label">Abonnee</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="6">
          Account Pages
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/sign-out">
            <span className="icon">{logout}</span>
            <span className="label">Sign Out</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
