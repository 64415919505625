import React, { useContext, Component } from 'react';
import { AuthContext } from '../authService';
import { color } from '../theme';
import { Link, withRouter } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  message,
} from "antd";
import apiUrl from "./config";
const { Title } = Typography;
const { Header, Footer, Content } = Layout;
import { Redirect } from 'react-router-dom';
class SignIn extends Component {
  static contextType = AuthContext; // Définir le contexte utilisé

  state = {
    loading: false,
    errorMessage: '',
  };

  onFinish = async (values) => {
    this.setState({ loading: true, errorMessage: '' });
    const url = `${apiUrl}/api/auth/`; // Utilisez l'URL du serveur API
    const data = {
      username: values.email,
      password: values.password
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.status === 400) {
        this.setState({ errorMessage: 'Incorrect username or password' });
      } else if (response.status === 200) {
        const result = await response.json();
        console.log('User ID:', result.id);
        this.context.login(result); // Utiliser la fonction login du contexte
        this.props.history.push('/dashboard');
      } else {
        console.log(response.status);
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      this.setState({ errorMessage: 'Probleme de connexion. Veuillez reessayer.' });
    } finally {
      this.setState({ loading: false });
    }
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  
  render() {
    return (
      <Layout className="layout-default layout-signin" >
        <Header>
          <div className="header-col header-brand">
            <h5>IN STATION</h5>
          </div>
          <div className="header-col header-nav">
            <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1">
                <Link to="/dashboard">
                  SignIn
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="input password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>


                {this.state.errorMessage && (
                  <Form.Item>
                    <p className="text-danger">{this.state.errorMessage}</p>
                  </Form.Item>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", backgroundColor: color}}
                    loading={this.state.loading}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
        <Footer>          
          <p className="copyright">
            {" "}
            Copyright © 2024
          </p>
        </Footer>
      </Layout>
    );
  }
}
export default withRouter(SignIn);



