import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Button,
} from "antd";


import { NavLink, Link } from "react-router-dom";
import { MenuOutlined,UserOutlined } from "@ant-design/icons";

const toggler = [
  <MenuOutlined key={0} style={{ fontSize: "20px", color: "#000" }} />,
];
const profile = [
  <UserOutlined key={0} style={{ fontSize: "20px", color: "#000" }} />,
];

function Header({
  name,
  subName,
  onPress,
}) {

  const [visible, setVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");

  useEffect(() => window.scrollTo(0, 0));


  return (
    <>
      
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {name.replace("/", "")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Link to="#" className="btn-sign-in">
            {profile}
            <span>Profile</span>
          </Link>
          
        </Col>
      </Row>
    </>
  );
}

export default Header;
