import React, { useContext } from 'react';
import { AuthContext } from '../authService';
import { useHistory } from 'react-router-dom';

const Profile = () => {
  const { logout } = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push('/sign-in');
  };

  // Exécute handleLogout lors du rendu du composant
  handleLogout();

  // Rendu du composant
  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default Profile;
