import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Ventes from "./pages/Ventes";
import SignOut from "./pages/SignOut";
import Produits from "./pages/Produits";
import Abonnee from "./pages/Abonnee";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AuthProvider, { AuthContext } from './authService';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {({ user }) => (
      <Route
        {...rest}
        render={(props) =>
          user ? (
            <Component {...props} />
          ) : (
            <Redirect to="/sign-in" />
          )
        }
      />
    )}
  </AuthContext.Consumer>
);

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Switch>
          <Route path="/sign-in" exact component={SignIn} />
          <Main>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/" component={Home} />
            <Route exact path="/produits" component={Produits} />
            <Route exact path="/pompiste" component={Ventes} />
            <Route exact path="/sign-out" component={SignOut} />
            <Route exact path="/abonnee" component={Abonnee} />
          </Main>
        </Switch>
      </AuthProvider>
    </div>
  );
}

export default App;
