import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "./config";
import { AuthContext } from "../authService";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { deepOrange } from '@mui/material/colors';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';

const defaultSalesData = {
  PompisteH: [
    { id: 1, product: 'Product A', code: '001', quantity: 10, create_at: '2023-06-01', status: true, subscriber: 'Subscriber 1', user: 'User 1' },
    { id: 2, product: 'Product B', code: '002', quantity: 20, create_at: '2023-06-02', status: false, subscriber: 'Subscriber 2', user: 'User 2' },
  ],
  PompisteN: [
    { id: 3, product: 'Product C', code: '003', quantity: 30, create_at: '2023-06-03', status: true, subscriber: 'Subscriber 3', user: 'User 3' },
    { id: 4, product: 'Product D', code: '004', quantity: 40, create_at: '2023-06-04', status: false, subscriber: 'Subscriber 4', user: 'User 4' },
  ],
  PompisteP: [
    { id: 5, product: 'Product E', code: '005', quantity: 50, create_at: '2023-06-05', status: true, subscriber: 'Subscriber 5', user: 'User 5' },
    { id: 6, product: 'Product F', code: '006', quantity: 60, create_at: '2023-06-06', status: false, subscriber: 'Subscriber 6', user: 'User 6' },
  ],
};

const pompistes = [
  { id: 1, nom: 'Pompiste H' },
  { id: 2, nom: 'Pompiste N' },
  { id: 3, nom: 'Pompiste P' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function SalesTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [salesData, setSalesData] = useState(defaultSalesData.PompisteH);
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const updateSalesData = (pompisteKey) => {
    setSalesData(defaultSalesData[pompisteKey]);
  };

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/sales`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const transformedData = response.data.map(item => ({
          id: item.id,
          product: item.product,
          code: item.code,
          quantity: parseFloat(item.quantity),
          create_at: item.create_at,
          status: item.status,
          subscriber: item.subscriber || 'N/A',
          user: item.user
        }));
        setSalesData(transformedData);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [token]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = salesData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - salesData.length) : 0;

  const visibleRows = stableSort(salesData, getComparator(order, orderBy))
    .slice(page, page * rowsPerPage + rowsPerPage);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            {pompistes.map((pompiste) => (
              <Card
                key={pompiste.id}
                onClick={() => updateSalesData(`Pompiste${pompiste.nom.charAt(pompiste.nom.length - 1)}`)}
                sx={{ cursor: 'pointer', p: 2 }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ bgcolor: deepOrange[500] }}>{pompiste.nom.charAt(pompiste.nom.length - 1)}</Avatar>
                  <Box>
                    <Typography variant="h6">{pompiste.nom}</Typography>
                    <Typography variant="body2">{pompiste.vente} ventes</Typography>
                    <Typography variant="body2">{pompiste.addresse}</Typography>
                    <Typography variant="body2">{pompiste.telephone}</Typography>
                  </Box>
                </Stack>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={salesData.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.product}
                          </TableCell>
                          <TableCell align="right">{row.code}</TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{row.create_at}</TableCell>
                          <TableCell align="right">{row.status ? 'Actif' : 'Inactif'}</TableCell>
                          <TableCell align="right">{row.subscriber}</TableCell>
                          <TableCell align="right">{row.user}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={8} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={salesData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
